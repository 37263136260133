// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    // links

    a:not(.button) {
        color: $accent;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $accent_alt;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        font-family: $heading-font;
        font-weight: 400;
    }

    h1 {
        color: $foreground;
        font-size: remify(25, 16);
        line-height: remify(30, 25);
        margin: remify(0 0 12.5, 25);
    }

    h2 {
        font-size: remify(16.8, 16);
        line-height: remify(20, 16.8);
        margin: 0;
    }

    h3 {
        color: $foreground;
        font-size: remify(16.8, 16);
        line-height: remify(20, 16.8);
        margin: 0;
    }

    h4 {
        font-size: remify(15.8, 16);
        line-height: remify(18.8, 15.8);
        margin: 0;
    }

    h5 {
        color: $foreground;
        font-size: remify(15.8, 16);
        line-height: remify(18.8, 15.8);
        margin: 0;
    }

    h6 {
        font-size: remify(14.8, 16);
        line-height: remify(17.6, 14.8);
        margin: 0;
    }

    // text
    p,
    ol,
    ul,
    table {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(13, 16);
        font-weight: 400;
        line-height: remify(18, 13);
        margin: remify(0 0 10, 13);
    }

    ol,
    ul {
        overflow: hidden;
        padding-left: remify(18, 14);
    }

    ol li,
    ul li {
        margin-bottom: remify(10, 13);
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid $primary_alt;
        padding: remify(7.5 15, 13);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td:before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $primary;
        color: $light;
        font-family: $heading-font;
        font-size: remify(16, 13);
        font-weight: 400;
        padding: remify(10 15, 16);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: lighten($primary, 60);
    }

    // scrolling responisve tables

    @media screen and (max-width: $s-break - (1 / 16)) {
        table {
            display: block;
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        border: remify(1) solid darken($background_alt, 10);
        border-left: 0;
        border-right: 0;
        font-style: italic;
        margin-left: remify(40, 16);
        margin-right: remify(40, 16);
        padding: remify(21 0, 16);
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($foreground, 50);
        font-size: remify(18, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0 !important;
    }

    // horizontal rule

    hr {
        background:
            url("../media/background-divider_circle.svg") left center / remify(6 6, 16) no-repeat,                                      // left circle
            url("../media/background-divider_square.svg") remify(3, 16) center / calc(100% - #{remify(6, 16)}) remify(2, 16) no-repeat, // line
            url("../media/background-divider_circle.svg") right center / remify(6 6, 16) no-repeat;                                     // right circle
        border: 0;
        clear: both;
        font-size: remify(16, 16);
        height: remify(35, 16);
        margin: remify(0 0 10);
        width: 100%;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
        min-height: remify(50, 16);
        position: relative;
    }

    img:before {
        background: $background_alt;
        border: remify(1) dotted darken($background_alt, 10);
        border-radius: remiefy(5, 16);
        content: "\0020";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    img:after {
        color: lighten($foreground, 20);
        content: "\F127\0020" attr(alt);
        display: block;
        font-family: "FontAwesome";
        font-size: remify(16, 16);
        font-style: normal;
        left: 0;
        position: absolute;
        text-align: center;
        top: remify(5, 16);
        width: 100%;
    }

    // alignment

    .alignfull {
        display: block;
        width: 100%;
    }

    @supports (width: 100vw) {
        .alignfull {
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            max-width: none;
            position: relative;
            right: 50%;
            width: 100vw;
        }
    }

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    // dark variant

    &.-dark a {
        color: $accent;
    }

    &.-dark a:focus,
    &.-dark a:hover {
        color: $accent_alt;
    }

    &.-dark h1,
    &.-dark h2,
    &.-dark h3,
    &.-dark h4,
    &.-dark h5,
    &.-dark h6,
    &.-dark ol,
    &.-dark p,
    &.-dark table,
    &.-dark ul {
        color: $light;
    }
}

// Desktop variant

@media screen and (min-width: $s-break) {
    .user-content {
        h1 {
            font-size: remify(36, 16);
        }

        h2,
        h3 {
            font-size: remify(21, 16);
        }

        h4,
        h5 {
            font-size: remify(19, 16);
        }

        h6 {
            font-size: remify(17, 16);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(16, 16);
        }

        hr {
            font-size: remify(32, 16);
        }
    }
}
