// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout_container {
    & {
        display: block; // override _noncritical
    }
}

.callout {
    & {
        background: $background;
        box-shadow: remify(0 0 6) transparentize($dark, 0.8), inset remify(0 0 0 7) $background, inset remify(0 0 0 8) $accent;
        margin: remify(0 0 20);
        padding: remify(8);
    }
}

.callout_figure {
    & {
        margin: 0;
    }
}

.callout_image {
    & {
        display: block;
        width: 100%;
    }
}

.callout_header {
    & {
        padding: remify(20 20 18);
    }
}

.callout_title {
    & {
        font-size: remify(20, 16);
        text-align: center;
        margin: 0;
    }
}

.callout_content {
    & {
        padding: remify(0 20 6, 16);
    }
}

.callout_text {
    & {
        font-size: remify(12.8, 16);
        text-align: center;
    }
}
