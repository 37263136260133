// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

// testimonial variant

.article.-testimonial {
    .article_text {
        font-size: remify(18, 16);
    }

    .article_user-content p,
    .article_user-content ol,
    .article_user-content ul,
    .article_user-content table {
        font-size: remify(25, 16);
    }
}
