// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: $primary;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $primary_alt;
    }
}

// arrow variant

.link.-arrow {
    & {
        background-image: url("../media/icon-arrow.svg");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: remify(28 28, 21);
        display: inline-block;
        font-size: remify(21, 16);
        padding: remify(3 34 3 0, 21);
    }
}
