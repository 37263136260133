@charset "UTF-8";
/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * FontAwesome Icons (http://fortawesome.github.io/Font-Awesome/icons/)     *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
.login-form:after, .menu-list_container:after, .menu-list:after {
  content: "" !important;
  clear: both !important;
  display: table !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._block {
  display: block !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._visuallyhidden {
  left: -999em !important;
  left: -100vw !important;
  position: absolute !important;
  top: -999em !important;
  top: -100vh !important; }

._warning {
  color: #9F0000 !important; }

/* visibility classes */
@media screen and (min-width: 48em) {
  ._mobile:not(._tablet):not(._desktop) {
    display: none !important; } }

@media screen and (max-width: 47.9375em) and (min-width: 64em) {
  ._tablet {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet._desktop:not(._mobile) {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  ._desktop:not(._tablet):not(._mobile) {
    display: none !important; } }

@media screen and (min-width: 64em) {
  ._mobile._tablet:not(._desktop) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._mobile._desktop {
    display: none !important; } }

/* ------------------------------------------------------------------------ ** Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Link
\* ------------------------------------------------------------------------ */
.link {
  color: #435667;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.15s; }

.link:focus, .link:hover {
  color: #536B80; }

.link.-arrow {
  background-image: url("../media/icon-arrow.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 1.33333em 1.33333em;
  display: inline-block;
  font-size: 1.3125em;
  padding: 0.14286em 1.61905em 0.14286em 0em; }

/* ------------------------------------------------------------------------ * * User Content
\* ------------------------------------------------------------------------ */
.user-content a:not(.button) {
  color: #9AAAB7;
  text-decoration: none;
  transition: color 0.15s; }

.user-content a:not(.button):hover {
  color: #B0C2D1; }

.user-content h1,
.user-content h2,
.user-content h3,
.user-content h4,
.user-content h5,
.user-content h6 {
  color: #435667;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif;
  font-weight: 400; }

.user-content h1 {
  color: #000000;
  font-size: 1.5625em;
  line-height: 1.2em;
  margin: 0em 0em 0.5em; }

.user-content h2 {
  font-size: 1.05em;
  line-height: 1.19048em;
  margin: 0; }

.user-content h3 {
  color: #000000;
  font-size: 1.05em;
  line-height: 1.19048em;
  margin: 0; }

.user-content h4 {
  font-size: 0.9875em;
  line-height: 1.18987em;
  margin: 0; }

.user-content h5 {
  color: #000000;
  font-size: 0.9875em;
  line-height: 1.18987em;
  margin: 0; }

.user-content h6 {
  font-size: 0.925em;
  line-height: 1.18919em;
  margin: 0; }

.user-content p,
.user-content ol,
.user-content ul,
.user-content table {
  color: #000000;
  font-family: "Raleway", "Arial", "Helvetica", sans-serif;
  font-size: 0.8125em;
  font-weight: 400;
  line-height: 1.38462em;
  margin: 0em 0em 0.76923em; }

.user-content ol,
.user-content ul {
  overflow: hidden;
  padding-left: 1.28571em; }

.user-content ol li,
.user-content ul li {
  margin-bottom: 0.76923em; }

.user-content table {
  border-collapse: collapse;
  width: 100%; }

.user-content table td,
.user-content table th {
  border: 0.0625rem solid #536B80;
  padding: 0.57692em 1.15385em;
  text-align: left;
  vertical-align: top; }

.user-content table tbody th,
.user-content table > tr > th,
.user-content table[data-stacked="true"] tbody td:before {
  font-weight: 700;
  text-transform: uppercase; }

.user-content table thead td,
.user-content table thead th {
  background: #435667;
  color: #FFFFFF;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif;
  font-size: 1.23077em;
  font-weight: 400;
  padding: 0.625em 0.9375em; }

.user-content table > tr:nth-child(even) td,
.user-content table > tr:nth-child(even) th,
.user-content table tbody tr:nth-child(even) td,
.user-content table tbody tr:nth-child(even) th {
  background: #eaeef2; }

@media screen and (max-width: 47.9375em) {
  .user-content table {
    display: block;
    overflow: auto;
    padding-bottom: 1px; } }

.user-content blockquote {
  border: 0.0625rem solid #cccccc;
  border-left: 0;
  border-right: 0;
  font-style: italic;
  margin-left: 2.5em;
  margin-right: 2.5em;
  padding: 1.3125em 0em; }

.user-content blockquote p,
.user-content blockquote ol,
.user-content blockquote ul,
.user-content blockquote table {
  color: gray;
  font-size: 1.125em; }

.user-content blockquote > :last-child {
  margin-bottom: 0 !important; }

.user-content hr {
  background: url("../media/background-divider_circle.svg") left center/0.375em 0.375em no-repeat, url("../media/background-divider_square.svg") 0.1875em center/calc(100% - 0.375em) 0.125em no-repeat, url("../media/background-divider_circle.svg") right center/0.375em 0.375em no-repeat;
  border: 0;
  clear: both;
  font-size: 1em;
  height: 2.1875em;
  margin: 0rem 0rem 0.625rem;
  width: 100%; }

.user-content img {
  display: block;
  height: auto !important;
  max-width: 100%;
  min-height: 3.125em;
  position: relative; }

.user-content img:before {
  background: #E5E5E5;
  border: 0.0625rem dotted #cccccc;
  border-radius: remiefy(5, 16);
  content: "\0020";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%; }

.user-content img:after {
  color: #333333;
  content: " " attr(alt);
  display: block;
  font-family: "FontAwesome";
  font-size: 1em;
  font-style: normal;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0.3125em;
  width: 100%; }

.user-content .alignfull {
  display: block;
  width: 100%; }

@supports (width: 100vw) {
  .user-content .alignfull {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: none;
    position: relative;
    right: 50%;
    width: 100vw; } }

.user-content .alignleft {
  float: left;
  margin-right: 1.5625em;
  max-width: 33.33333%; }

.user-content .alignright {
  float: right;
  margin-left: 1.5625em;
  max-width: 33.33333%; }

.user-content .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.user-content.-dark a {
  color: #9AAAB7; }

.user-content.-dark a:focus,
.user-content.-dark a:hover {
  color: #B0C2D1; }

.user-content.-dark h1,
.user-content.-dark h2,
.user-content.-dark h3,
.user-content.-dark h4,
.user-content.-dark h5,
.user-content.-dark h6,
.user-content.-dark ol,
.user-content.-dark p,
.user-content.-dark table,
.user-content.-dark ul {
  color: #FFFFFF; }

@media screen and (min-width: 48em) {
  .user-content h1 {
    font-size: 2.25em; }
  .user-content h2,
  .user-content h3 {
    font-size: 1.3125em; }
  .user-content h4,
  .user-content h5 {
    font-size: 1.1875em; }
  .user-content h6 {
    font-size: 1.0625em; }
  .user-content p,
  .user-content ol,
  .user-content ul,
  .user-content table {
    font-size: 1em; }
  .user-content hr {
    font-size: 2em; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Article
\* ------------------------------------------------------------------------ */
.article.-excerpt:not(:last-of-type) {
  border-bottom: 0.0625rem solid #cccccc;
  margin-bottom: 1.25em; }

.article.-excerpt .article_title {
  font-size: 1.125em; }

.article.-excerpt .article_header > .article_title:not(:last-child) {
  margin-bottom: 0; }

.article.-excerpt .article_header > .article_title:not(:last-child) + * {
  margin-bottom: 0.625em; }

.article.-testimonial .article_text {
  color: #435667;
  font-size: 0.9em; }

.article.-testimonial .article_user-content p,
.article.-testimonial .article_user-content ol,
.article.-testimonial .article_user-content ul,
.article.-testimonial .article_user-content table {
  color: #435667;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif;
  font-size: 1.25em;
  line-height: 1.3em;
  margin-bottom: 0.8em;
  text-align: center; }

/* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
.callout_container {
  display: block; }

.callout {
  background: #FFFFFF;
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.2), inset 0rem 0rem 0rem 0.4375rem #FFFFFF, inset 0rem 0rem 0rem 0.5rem #9AAAB7;
  margin: 0rem 0rem 1.25rem;
  padding: 0.5rem; }

.callout_figure {
  margin: 0; }

.callout_image {
  display: block;
  width: 100%; }

.callout_header {
  padding: 1.25rem 1.25rem 1.125rem; }

.callout_title {
  font-size: 1.25em;
  text-align: center;
  margin: 0; }

.callout_content {
  padding: 0em 1.25em 0.375em; }

.callout_text {
  font-size: 0.8em;
  text-align: center; }

/* ------------------------------------------------------------------------ * * Divider
\* ------------------------------------------------------------------------ */
.divider {
  background: url("../media/background-divider_circle.svg") left center/0.375em 0.375em no-repeat, url("../media/background-divider_square.svg") 0.1875em center/calc(50% - 1.59375em) 0.125em no-repeat, url("../media/logo-icon.svg") center center/2.1875em 2.1875em no-repeat, url("../media/background-divider_square.svg") right 0.1875em center/calc(50% - 1.59375em) 0.125em no-repeat, url("../media/background-divider_circle.svg") right center/0.375em 0.375em no-repeat;
  border: 0;
  clear: both;
  font-size: 1em;
  height: 2.1875em;
  margin: 0em 0em 1.5625em;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Fluidbox https://github.com/terrymun/Fluidbox
\* ------------------------------------------------------------------------ */
@keyframes fluidboxLoading {
  0% {
    transform: translate(-50%, -50%) rotateX(0) rotateY(0); }
  50% {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0); }
  100% {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg); } }

.fluidbox {
  outline: none; }

.fluidbox__overlay {
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  cursor: zoom-out;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* Transition time for overlay is halved to ensure that flickering doesn't happen */
  transition: all 0.5s ease-in-out; }
  .fluidbox--opened .fluidbox__overlay {
    pointer-events: auto; }

.fluidbox__wrap {
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s ease-in-out; }

.fluidbox__thumb {
  transition: opacity 0s ease-in-out 0s;
  /* To prevent flickering, we delay the showing of the image */ }
  .fluidbox--closed .fluidbox__thumb {
    transition: opacity 0s ease-in-out 0s; }

.fluidbox__ghost {
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  transition-duration: 0s, 0.5s;
  transition-delay: 0s;
  -webkit-transition-property: opacity, -webkit-transform;
  /* autoprefixer: off */
  transition-property: opacity, -webkit-transform;
  /* autoprefixer: off */
  transition-property: opacity, transform;
  /* autoprefixer: off */ }
  .fluidbox--opened .fluidbox__ghost {
    cursor: pointer;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out; }
  .fluidbox--closed .fluidbox__ghost {
    transition-delay: 0.5s, 0s; }

.fluidbox__loader {
  opacity: 0;
  perspective: 200px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .fluidbox__loader::before {
    /* You can replace this with any color you want, or even a loading gif if desired */
    background-color: rgba(255, 255, 255, 0.85);
    content: '';
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    padding-bottom: 20%;
    transform: translate(-50%, -50%);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-delay: 0s; }

.fluidbox--loading .fluidbox__loader {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.5s; }
  .fluidbox--loading .fluidbox__loader::before {
    animation: fluidboxLoading 1s 0s infinite ease-in-out forwards; }

.fluidbox__overlay {
  background-color: rgba(0, 0, 0, 0.75); }

/* ------------------------------------------------------------------------ * * Login Form
\* ------------------------------------------------------------------------ */
.login-form_input-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0em 0em 0.625em; }

.login-form_input-container .login-form_text.-small {
  font-size: 0.75em; }

.login-form_input-container .login-form_text.-label {
  flex: none;
  margin: 0em 0.625em 0em 0em; }

.login-form_input-container .login-form_input {
  flex: 1 1 auto;
  margin-bottom: 0;
  width: auto; }

.login-form_input-container .login-form_input + .login-form_text {
  flex: none;
  margin-top: 0.83333em;
  width: 100%; }

.login-form.-register .login-form_title {
  font-size: 1.25em;
  margin-bottom: 1em; }

/* ------------------------------------------------------------------------ * * Logo
\* ------------------------------------------------------------------------ */
.logo {
  display: block;
  text-decoration: none;
  transition: opacity 0.15s; }

.logo:not(.-nohover):hover {
  opacity: 0.9; }

.logo_image, .custom-logo {
  display: block;
  width: 100%; }

.custom-logo {
  height: auto !important; }

/* ------------------------------------------------------------------------ * * Menu List
\* ------------------------------------------------------------------------ */
.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center; }

.menu-list_item {
  float: left;
  position: relative; }

.menu-list_item.-parent {
  display: flex;
  flex-wrap: wrap; }

.menu-list_item.-mega {
  position: static; }

.menu-list_link {
  display: block;
  flex: 1 1 auto;
  text-decoration: none; }

.menu-list_toggle {
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0; }

.menu-list_toggle > .fa {
  transition: transform 0.15s; }

.menu-list_item.is-active > .menu-list_toggle > .fa {
  transform: rotate(-180deg); }

.menu-list.-center {
  float: right;
  position: relative;
  right: 50%; }

.menu-list.-center > .menu-list_item {
  left: 50%; }

.menu-list.-flex {
  display: flex; }

.menu-list.-flex > .menu-list_item {
  flex: 1 1 auto; }

.menu-list.-vertical {
  text-align: left; }

.menu-list.-vertical.-right {
  text-align: right; }

.menu-list.-vertical > .menu-list_item {
  float: none; }

.menu-list[class*="-tier"] {
  min-width: 100%; }

.menu-list[class*="-tier"].-accordion {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s, visibility 0s 0.25s;
  visibility: hidden;
  width: 100%; }

.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-accordion {
  max-height: 31.25em;
  transition: max-height 0.25s;
  visibility: visible; }

.menu-list[class*="-tier"].-overlay {
  left: -999em;
  opacity: 0;
  position: absolute;
  transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  top: -999em;
  visibility: hidden; }

.menu-list_item.-parent:hover > .menu-list[class*="-tier"].-overlay,
.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-overlay {
  opacity: 1;
  transition: opacity 0.15s;
  visibility: visible; }

.menu-list[class*="-tier"].-overlay.-reverse {
  transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s; }

.menu-list_item.-parent:hover > .menu-list[class*="-tier"].-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-overlay.-reverse {
  left: auto;
  right: 0;
  transition: opacity 0.15s; }

.menu-list_item.-parent:hover > .menu-list.-tier1.-overlay,
.menu-list_item.-parent.is-active > .menu-list.-tier1.-overlay {
  left: 0;
  top: 100%; }

.menu-list.-tier1.-overlay.-reverse {
  left: auto;
  right: 999em; }

.menu-list_item.-parent:hover > .menu-list.-tier1.-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list.-tier1.-overlay.-reverse {
  left: auto;
  right: 0; }

.menu-list_item.-parent:hover > .menu-list.-tier2.-overlay,
.menu-list_item.-parent.is-active > .menu-list.-tier2.-overlay {
  left: 100%;
  top: 0; }

.menu-list.-tier2.-overlay.-reverse {
  left: auto;
  right: 999em; }

.menu-list_item.-parent:hover > .menu-list.-tier2.-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list.-tier2.-overlay.-reverse {
  left: auto;
  right: 100%; }

.menu-list_container.-mega {
  display: flex;
  left: -999em;
  opacity: 0;
  position: absolute;
  right: 999em;
  top: -999em;
  transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  visibility: hidden; }

.menu-list_item.-mega.-parent:hover > .menu-list_container.-mega,
.menu-list_item.-mega.-parent.is-active > .menu-list_container.-mega {
  left: 0;
  opacity: 1;
  top: 100%;
  transition: opacity 0.15s;
  right: 0;
  visibility: visible; }

.menu-list_container.-mega > .menu-list.-tier1 {
  flex: 1 1 auto;
  min-width: 0; }

.menu-list.-navigation {
  font-family: "Raleway", "Arial", "Helvetica", sans-serif;
  font-size: 1.25em;
  font-weight: 400; }

.menu-list.-navigation .menu-list_link {
  color: #435667;
  padding: 0.675em 0.725em 0.625em;
  transition: background-color 0.15s, color 0.15s; }

.menu-list.-navigation .menu-list_link:focus,
.menu-list.-navigation .menu-list_link:hover,
.menu-list.-navigation > .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation > .menu-list_item.-parent.is-active > .menu-list_link {
  background-color: #435667;
  color: #FFFFFF; }

.menu-list.-navigation .menu-list_toggle {
  color: #435667;
  font-size: 1em;
  padding: 0.675em 0.725em 0.625em;
  transition: background-color 0.15s, color 0.15s; }

.menu-list.-navigation .menu-list_toggle:focus,
.menu-list.-navigation .menu-list_toggle:hover,
.menu-list.-navigation .menu-list_item.-parent:hover > .menu-list_toggle,
.menu-list.-navigation .menu-list_item.-parent.is-active > .menu-list_toggle {
  background-color: #435667;
  color: #FFFFFF; }

.menu-list.-navigation .menu-list_container.-mega,
.menu-list.-navigation .menu-list.-tier1.-accordion,
.menu-list.-navigation .menu-list.-tier1.-overlay {
  background-color: #435667; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_link {
  color: #FFFFFF; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier1 .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
  background-color: #4d6376; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle {
  color: #FFFFFF; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle:focus,
.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle:hover,
.menu-list.-navigation .menu-list.-tier1 .menu-list_item.-parent:hover > .menu-list_toggle,
.menu-list.-navigation .menu-list.-tier1 .menu-list_item.-parent.is-active > .menu-list_toggle {
  background-color: #4d6376; }

.menu-list.-navigation .menu-list.-tier2.-accordion,
.menu-list.-navigation .menu-list.-tier2.-overlay {
  background-color: #577086; }

.menu-list.-navigation .menu-list.-tier2 .menu-list_link {
  color: #FFFFFF; }

.menu-list.-navigation .menu-list.-tier2.-accordion .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier2.-accordion .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
  background-color: #617d95; }

.menu-list.-navigation .menu-list_container.-mega .menu-list.-tier2 .menu-list_link:before {
  content: "\002D\0020\0020"; }

.menu-list.-meta {
  color: #1A1A1A;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif;
  font-size: 0.875em;
  font-weight: 700;
  text-transform: uppercase; }

.menu-list.-meta .menu-list_item:not(:last-child) {
  margin-right: 1.42857em; }

.menu-list.-meta .menu-list_link {
  color: #1A1A1A;
  display: inline-block; }

.menu-list.-meta .menu-list_link:hover {
  color: #343434; }

.menu-list.-pagination {
  font-family: "Raleway", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 400; }

.menu-list.-pagination .menu-list_item:not(:last-child) {
  margin-right: 0.57143em; }

.menu-list.-pagination .menu-list_link.-current {
  color: #000000;
  cursor: auto; }

.menu-list.-icons {
  color: #FFFFFF;
  font-family: "Raleway", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em; }

.menu-list.-icons.-notext {
  font-size: 1.1875em; }

.menu-list.-icons.-vertical .menu-list_item:not(:last-child) {
  margin: 0em 0em 0.5em; }

.menu-list.-icons .menu-list_link {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 2em 2em;
  color: #FFFFFF;
  min-height: 2em;
  min-width: 2em;
  padding: 0.42857em 0.42857em 0.42857em 2.71429em; }

.menu-list.-icons.-notext .menu-list_link {
  margin: 0em 0.5em;
  padding: 0; }

.menu-list.-icons .menu-list_link:focus,
.menu-list.-icons .menu-list_link:hover {
  color: #E5E5E5; }

.menu-list.-icons .menu-list_item.-email > .menu-list_link {
  background-image: url("../media/icon-email.svg"); }

.menu-list.-icons .menu-list_item.-phone > .menu-list_link {
  background-image: url("../media/icon-phone.svg"); }

.menu-list.-icons .menu-list_item.-facebook > .menu-list_link {
  background-image: url("../media/icon-facebook.svg");
  color: #435667;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif; }

.menu-list.-icons .menu-list_item.-facebook > .menu-list_link:focus,
.menu-list.-icons .menu-list_item.-facebook > .menu-list_link:hover {
  color: #536B80; }

/* ------------------------------------------------------------------------ * * Menu Toggle
\* ------------------------------------------------------------------------ */
.menu-toggle {
  background: #435667;
  background-clip: padding-box;
  border: 0.1875rem solid transparent;
  outline: 0.0625rem solid #435667;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Libre Baskerville", "Times", "Times New Roman", serif;
  font-size: 1.25em;
  margin: 0;
  padding: 0.35em 1.75em 0.25em 3.5em;
  position: relative;
  transition: background 0.15s, box-shadow 0.15s; }

.menu-toggle:focus, .menu-toggle:hover {
  background-color: #536B80;
  box-shadow: 0rem 0rem 0rem 0.03125rem #536B80; }

.menu-toggle:before, .menu-toggle:after {
  background: currentColor;
  bottom: 0;
  content: "\0020";
  display: block;
  height: 0.1em;
  left: 1.75em;
  margin: auto;
  position: absolute;
  top: 0;
  transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
  width: 1em; }

.menu-toggle.is-active:before, .menu-toggle.is-active:after {
  transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s; }

.menu-toggle:before {
  box-shadow: 0em 0.3em 0em 0em currentColor;
  top: -0.6em; }

.menu-toggle.is-active:before {
  box-shadow: 0 0 0 0 transparent;
  transform: rotate(45deg);
  top: 0; }

.menu-toggle:after {
  top: 0.6em; }

.menu-toggle.is-active:after {
  transform: rotate(-45deg);
  top: 0; }

/* ------------------------------------------------------------------------ * * Modal
\* ------------------------------------------------------------------------ */
.modal {
  background: #FFFFFF;
  border: 0.0625rem solid #cccccc;
  bottom: 0;
  display: block;
  height: 25em;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  overflow: auto;
  padding: 1.25em;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.15s, transform 0s 0.15s;
  transform: translateX(-100%);
  visibility: hidden;
  width: 46.875em;
  z-index: 999; }

.modal.is-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.15s;
  visibility: visible; }

@media screen and (min-width: 601px) {
  .admin-bar .modal {
    top: 46px; } }

@media screen and (min-width: 783px) {
  .admin-bar .modal {
    top: 32px; } }

.modal_menu-toggle {
  position: absolute;
  right: 0.75em;
  top: 0.75em; }

/* ------------------------------------------------------------------------ * * Ninja Forms
\* ------------------------------------------------------------------------ */
.nf-form-hp {
  left: -999em;
  position: absolute;
  top: -999em; }

.nf-field-container .pikaday__container {
  display: block; }

.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  list-style: none;
  margin-bottom: 0;
  padding: 0; }

.nf-field-container.label-above .field-wrap,
.nf-field-container.label-right .field-wrap,
.nf-field-container.label-below .field-wrap,
.nf-field-container.label-left .field-wrap {
  align-items: flex-start;
  display: flex; }

.nf-field-container.label-right .field-wrap .nf-field-label,
.nf-field-container.label-left .field-wrap .nf-field-label {
  flex: none; }

.nf-field-container.label-above .field-wrap .nf-field-element,
.nf-field-container.label-below .field-wrap .nf-field-element {
  width: 100%; }

.nf-field-container.label-right .field-wrap .nf-field-element,
.nf-field-container.label-left .field-wrap .nf-field-element {
  flex: 1 1 auto; }

.nf-field-container.label-above .field-wrap {
  flex-direction: column; }

.nf-field-container.label-right .nf-field-label {
  order: 2; }

.nf-field-container.label-right .nf-field-element {
  order: 1; }

.nf-field-container.label-below .field-wrap {
  flex-direction: column; }

.nf-field-container.label-below .nf-field-label {
  order: 2; }

.nf-field-container.label-below .nf-field-element {
  order: 1; }

/* ------------------------------------------------------------------------ * * Opinionated Tweaks
\* ------------------------------------------------------------------------ */
.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  margin-bottom: 1.5em; }

.nf-field-container .list-checkbox-wrap ul .text.-label, .nf-field-container .list-checkbox-wrap ul .-label.nf-form-fields-required,
.nf-field-container .list-radio-wrap ul .text.-label,
.nf-field-container .list-radio-wrap ul .-label.nf-form-fields-required {
  margin-bottom: 0.25em; }

.nf-field-container .stars {
  margin-bottom: 1.5em; }

.nf-field-container .nf-user-content {
  margin-top: -1.25em; }

.nf-field-container .nf-error-msg {
  font-size: 0.625em;
  margin-bottom: 2.4em;
  margin-top: -2em; }

.nf-field-container .g-recaptcha {
  margin-bottom: 1.5em; }

.nf-field-container.label-above .nf-field-label > .text.-label, .nf-field-container.label-above .nf-field-label > .-label.nf-form-fields-required {
  margin-bottom: 0.25em; }

.nf-field-container.checkbox-container.label-above .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-above .checkbox-wrap .-label.nf-form-fields-required {
  padding-left: 0;
  padding-top: 2em; }

.nf-field-container.checkbox-container.label-above .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-above .input.-checkbox + .-label.-checkbox.nf-form-fields-required:before {
  transform: none;
  top: 0; }

.nf-field-container.label-right .nf-field-label > .text.-label, .nf-field-container.label-right .nf-field-label > .-label.nf-form-fields-required {
  margin-left: 0.625em; }

.nf-field-container.label-below .nf-field-label > .text.-label, .nf-field-container.label-below .nf-field-label > .-label.nf-form-fields-required {
  margin-top: 0.25em; }

.nf-field-container.label-below .nf-field-element .input,
.nf-field-container.label-below .nf-field-element .stars,
.nf-field-container.label-below .list-checkbox-wrap ul,
.nf-field-container.label-below .list-radio-wrap ul {
  margin-bottom: 0;
  order: 1; }

.nf-field-container.checkbox-container.label-below .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-below .checkbox-wrap .-label.nf-form-fields-required {
  padding-left: 0;
  padding-bottom: 2em; }

.nf-field-container.checkbox-container.label-below .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-below .input.-checkbox + .-label.-checkbox.nf-form-fields-required:before {
  transform: none;
  bottom: 0;
  top: auto; }

.nf-field-container.label-left .nf-field-label > .text.-label, .nf-field-container.label-left .nf-field-label > .-label.nf-form-fields-required {
  margin-right: 0.625em; }

.nf-field-container.checkbox-container.label-left .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-left .checkbox-wrap .-label.nf-form-fields-required {
  display: inline-block;
  padding-left: 0;
  padding-right: 2em;
  width: auto; }

.nf-field-container.checkbox-container.label-left .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-left .input.-checkbox + .-label.-checkbox.nf-form-fields-required:before {
  left: auto;
  right: 0; }

.nf-field-container.checkbox-container.label-hidden .input.-checkbox + .text.-label.-checkbox, .nf-field-container.checkbox-container.label-hidden .input.-checkbox + .-label.-checkbox.nf-form-fields-required {
  text-indent: -999em; }

.nf-field-container.checkbox-container.label-hidden .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-hidden .input.-checkbox + .-label.-checkbox.nf-form-fields-required:before {
  text-indent: 0; }

/* ------------------------------------------------------------------------ * * Overlay Closer
\* ------------------------------------------------------------------------ */
.overlay-closer {
  background: rgba(0, 0, 0, 0.75);
  border: 0;
  bottom: 999em;
  content: "\0020";
  cursor: pointer;
  display: block;
  left: -999em;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 999em;
  transition: bottom 0s 0.15s, left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  top: -999em;
  visibility: hidden;
  width: 100%;
  z-index: 998; }

.overlay-closer.is-active {
  bottom: 0;
  left: 0;
  opacity: 1;
  right: 0;
  transition: opacity 0.15s;
  top: 0;
  visibility: visible; }

/* ------------------------------------------------------------------------ * * Search Form
\* ------------------------------------------------------------------------ */
.search-form {
  position: relative; }

.search-form_input {
  background: #FFFFFF;
  border: 0.0625rem solid #E5E5E5;
  color: #000000;
  font-family: "Raleway", "Arial", "Helvetica", sans-serif;
  font-size: 1em;
  margin: 0;
  outline: none;
  padding: 0.5em 2.375em 0.5em 0.75em;
  transition: border-color 0.15s;
  width: 100%; }

.search-form_input:focus, .search-form_input:hover {
  border-color: #9AAAB7; }

.search-form_button {
  background: none;
  border: 0;
  bottom: 0.0625rem;
  color: #000000;
  cursor: pointer;
  font-size: 1em;
  margin: 0;
  outline: none;
  padding: 0.625em 0.75em;
  position: absolute;
  right: 0.0625rem;
  top: 0.0625rem;
  transition: color 0.15s; }

.search-form_button:focus, .search-form_button:hover {
  color: #9AAAB7; }

/* ------------------------------------------------------------------------ * * StickyState https://github.com/soenkekluth/sticky-state
\* ------------------------------------------------------------------------ */
.sticky {
  position: sticky; }

.sticky.sticky-fixed.is-sticky {
  position: fixed;
  backface-visibility: hidden; }

.sticky.sticky-fixed.is-absolute {
  position: absolute; }

/* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-slide {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto; }

.swiper-slide {
  margin: 0;
  overflow: hidden; }

.swiper-picture {
  flex: none;
  position: relative;
  z-index: 1; }

.swiper-image {
  display: block;
  height: 20.625em;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; }

.swiper-caption {
  background: #000000;
  background-clip: padding-box;
  border: 0.1875rem solid transparent;
  flex: 1 1 auto;
  margin: -2.375rem 0.75rem 0.75rem;
  outline: 0.125rem solid #000000;
  padding: 1.25rem 1.40625rem;
  position: relative;
  z-index: 2; }

.swiper-link {
  color: #9AAAB7; }

.swiper-link:focus, .swiper-link:hover {
  color: #B0C2D1; }

.swiper-title {
  color: #FFFFFF;
  font-size: 1.90625em;
  line-height: 1.22951em;
  margin-bottom: 0.2623em; }

.swiper-button-prev,
.swiper-button-next {
  background: none;
  border: 0;
  color: #435667;
  display: block;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: opacity 0.15s;
  top: 0;
  width: 3.4375em; }

.swiper-container:hover .swiper-button-prev, .swiper-container:hover
.swiper-button-next {
  opacity: 0.5; }

.swiper-container:hover .swiper-button-prev:hover, .swiper-button-prev:focus, .swiper-container:hover
.swiper-button-next:hover,
.swiper-button-next:focus {
  opacity: 1; }

.swiper-button-prev .fa,
.swiper-button-next .fa {
  font-size: 3em;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
  left: 0; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
  right: 0; }

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 0rem 2.25rem 1.25rem;
  position: static;
  text-align: left; }

.swiper-pagination-bullet {
  background: #000000;
  border: 0.0625rem solid #000000;
  height: 0.625rem;
  opacity: 1;
  transition: background 0.15s, border 0.15s;
  width: 0.625rem; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0.25rem; }

.swiper-pagination-bullet-active {
  background-color: #FFFFFF;
  border-color: #435667; }

/* ------------------------------------------------------------------------ * * Widget
\* ------------------------------------------------------------------------ */
.widget {
  border: 0.0625rem solid #cccccc;
  margin: 0em 0em 1.5625em; }

.widget_title {
  background: #1A1A1A;
  color: #FFFFFF;
  font-size: 1.28571em;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0.55556em 0.83333em; }

.widget_content {
  padding: 0.625em 0.9375em; }

.widget_content > :last-child {
  margin-bottom: 0 !important; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Page
\* ------------------------------------------------------------------------ */
html,
body {
  height: 100%;
  width: 100%; }

body {
  background: #000000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.page_container {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Login
\* ------------------------------------------------------------------------ */
.login-block {
  position: relative; }

.login-block.-fullbleed {
  background: #FFFFFF; }

.login-block.-overlay {
  display: block;
  z-index: 999; }

.login_inner {
  background: #FFFFFF;
  margin: 0 auto;
  max-width: 93.75rem; }

.login-block.-fullbleed > .login_inner {
  background: none; }

.login-block.-overlay > .login_inner {
  background: #FFFFFF;
  border: 0.0625rem solid #cccccc;
  left: -999em;
  max-height: 100vw;
  max-width: 100vw;
  opacity: 0;
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  top: -999em;
  width: 46.875rem;
  visibility: hidden; }

.login-block.-overlay.is-active > .login_inner {
  left: 50%;
  opacity: 1;
  top: 50%;
  transition: opacity 0.15s;
  visibility: visible; }

.login_login-form {
  padding: 1.875rem 2.5rem 0rem; }

.login_login-form.-register {
  background: #e6e6e6;
  border-top: 0.0625rem solid #cccccc; }

.login_login-form .login-form_button {
  margin-bottom: -0.71429em; }

.login_button.-close {
  background: none;
  color: #cccccc;
  font-size: 1em;
  height: 2em;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.15s;
  width: 2em; }

.login_button.-close:focus, .login_button.-close:hover {
  color: #9AAAB7; }

.login_button.-close:before, .login_button.-close:after {
  background: currentColor;
  bottom: 0;
  content: "\0020";
  display: block;
  height: 0.0625rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.75em; }

.login_button.-close:before {
  transform: rotate(45deg); }

.login_button.-close:after {
  transform: rotate(-45deg); }

/* ------------------------------------------------------------------------ * * Navigation
\* ------------------------------------------------------------------------ */
.navigation-block {
  position: relative;
  z-index: 4; }

.navigation-block.-fullbleed {
  background: #FFFFFF; }

.navigation-block.-flyout {
  background: #FFFFFF;
  border-right: 0.0625rem solid #cccccc;
  bottom: 999em;
  display: block;
  left: -999em;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 999em;
  top: -999em;
  transform: translateX(-100%);
  transition: bottom 0s 0.15s, left 0s 0.15s, right 0s 0.15s, top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
  visibility: hidden;
  z-index: 999; }

.navigation-block.-flyout.-right {
  border-left: 0.0625rem solid #cccccc;
  border-right: 0;
  left: 999em;
  right: -999em;
  transform: translateX(100%); }

.navigation-block.-flyout.is-active {
  bottom: 0;
  left: 0;
  right: 5rem;
  transition: transform 0.15s;
  transform: translateX(0);
  top: 0;
  visibility: visible; }

.navigation-block.-flyout.-right.is-active {
  left: 5rem;
  right: 0; }

.navigation-block.-pulldown {
  background: #FFFFFF;
  border-right: 0.0625rem solid #cccccc;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: -999em;
  transform: translateY(-100%);
  transition: top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
  visibility: hidden;
  width: 100%;
  z-index: 999; }

.navigation-block.-pulldown.is-active {
  top: 0;
  transform: translateY(0);
  transition: transform 0.15s;
  visibility: visible; }

.navigation-block.-sticky {
  position: sticky;
  top: 0;
  width: 100% !important; }

.navigation-block.-sticky.is-sticky {
  border-bottom: 0.0625rem solid #cccccc; }

@media screen and (min-width: 601px) {
  .admin-bar .navigation-block.-sticky.is-sticky {
    top: 46px; } }

@media screen and (min-width: 783px) {
  .admin-bar .navigation-block.-sticky.is-sticky {
    top: 32px; } }

.navigation_inner {
  background: #FFFFFF;
  margin: 0 auto;
  max-width: 93.75rem; }

.navigation-block.-flyout > .navigation_inner,
.navigation-block.-pulldown > .navigation_inner {
  background: none;
  max-width: none; }

.navigation-block.-fullbleed > .navigation_inner {
  background: none; }

/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header-block {
  position: relative;
  z-index: 5; }

.header-block.-fullbleed {
  background: #000000;
  padding: 1.25rem 1.5625rem 2.5rem; }

.header_inner {
  background: #000000;
  margin: 0 auto;
  max-width: 93.75rem;
  padding: 1.25rem 1.5625rem 2.5rem; }

.header-block.-fullbleed > .header_inner {
  background: none;
  padding: 0; }

.header_logo {
  margin: 0 auto;
  max-height: 10.625rem;
  max-width: 16.875rem; }

.header_menu-toggle {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%) translateY(-50%); }

/* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
.hero-block {
  position: relative;
  z-index: 3; }

.hero-block.-fullbleed {
  /* bgImage: ignore */
  background-color: #9AAAB7;
  background-image: url("../media/background-fabric.jpg");
  background-position: center top;
  background-repeat: repeat-y; }

.hero_inner {
  /* bgImage: ignore */
  background-color: #9AAAB7;
  background-image: url("../media/background-fabric.jpg");
  background-position: center top;
  background-repeat: repeat-y;
  margin: 0 auto;
  max-width: 96.25rem; }

.hero-block.-fullbleed .hero_inner {
  background: none; }

.hero_figure {
  margin: 0;
  position: relative; }

.hero_image {
  display: block;
  width: 100%; }

.hero_header {
  background: #000000; }

.hero_title {
  color: #FFFFFF;
  margin: 0 auto;
  max-width: 93.75rem;
  padding: 0.625em 1.04167em; }

.hero_container.-fullbleed .hero_title {
  margin: 0 auto;
  max-width: 93.75rem; }

.hero_user-content {
  margin: 0 auto;
  max-width: 93.75rem;
  overflow: hidden;
  padding: 0em 1.5625em; }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content-block {
  position: relative;
  z-index: 2;
  background: url("../media/background-fabric.jpg") center top no-repeat, linear-gradient(to bottom, #9AAAB7 0rem, #9AAAB7 20rem, rgba(154, 170, 183, 0) 20rem), url("../media/background-wood.jpg") center top repeat-y;
  padding: 3.125rem 0rem; }

.content-block.-fullbleed {
  background: url("../media/background-wood.jpg") center top repeat-y;
  padding: 0rem 1.5625rem; }

.is-fluidboxactive .content-block {
  position: static; }

.content_inner {
  background: #FFFFFF;
  box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.2), inset 0rem 0rem 0rem 0.375rem #FFFFFF, inset 0rem 0rem 0rem 0.5rem #9AAAB7;
  margin: 0 auto;
  max-width: 93.75rem;
  padding: 2.5rem 1.5625rem; }

.content-block.-fullbleed > .content_inner {
  background: none;
  box-shadow: none;
  padding: 1.5625rem 0rem;
  position: relative; }

.content-block.-fullbleed > .content_inner:first-child:not(:only-child):before {
  background: #FFFFFF;
  bottom: 0;
  content: "\0020";
  display: block;
  left: 50%;
  margin: 0 -50vw;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1; }

.content-block.-fullbleed > .content_inner:only-child:before {
  border-bottom: 0.5rem solid #9AAAB7; }

.content-block.-fullbleed > .content_inner:last-child:not(:only-child) {
  padding-top: 0; }

.content-block.-fullbleed > .content_inner:last-child:not(:only-child):before {
  background: #FFFFFF;
  border-bottom: 0.5rem solid #9AAAB7;
  content: "\0020";
  display: block;
  height: 2.1875rem;
  left: 50%;
  margin: 0 -50vw;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1; }

.content_row.-padded {
  width: calc(100% + 3.75rem); }

.content_row.-padded > .col {
  padding-right: 3.75rem; }

.content_figure {
  margin: 0rem 0rem 0.9375rem; }

.content_image {
  display: block;
  width: 100%; }

.content_post {
  max-width: 79.375rem;
  margin: 0 auto; }

/* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
.footer-block {
  position: relative;
  z-index: 1;
  display: block; }

.footer-block.-fullbleed {
  background: #000000;
  box-shadow: inset 0rem 0.1875rem 0rem #000000, inset 0rem 0.28125rem 0rem #FFFFFF;
  padding: 1.875rem 1.5625rem; }

.footer_inner {
  background: #000000;
  box-shadow: inset 0rem 0.1875rem 0rem #000000, inset 0rem 0.28125rem 0rem #FFFFFF;
  margin: 0 auto;
  max-width: 62.5rem;
  padding: 1.875rem 1.5625rem; }

.footer-block.-fullbleed > .footer_inner {
  background: none;
  box-shadow: none;
  padding: 0; }

.footer_link {
  color: #9AAAB7; }

.footer_link:focus, .footer_link:hover {
  color: #B0C2D1; }

.footer_title {
  color: #9AAAB7;
  font-size: 1.15em;
  text-align: center;
  margin-bottom: 0; }

.footer_text {
  color: #FFFFFF;
  font-size: 0.6875em;
  text-align: center; }

.footer_logo {
  margin: 0rem auto 1.5625rem;
  width: 14.375em; }

@media screen and (min-width: 30em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 40em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 48em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Article
\* ------------------------------------------------------------------------ */
  .article.-testimonial .article_text {
    font-size: 1.125em; }
  .article.-testimonial .article_user-content p,
  .article.-testimonial .article_user-content ol,
  .article.-testimonial .article_user-content ul,
  .article.-testimonial .article_user-content table {
    font-size: 1.5625em; }
  /* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
  .callout {
    box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.2), inset 0rem 0rem 0rem 0.375rem #FFFFFF, inset 0rem 0rem 0rem 0.5rem #9AAAB7; }
  .row:last-of-type > .col > .callout:last-child {
    margin-bottom: 0; }
  .callout_header {
    padding: 1.5625rem 2.1875rem 1rem; }
  .callout_title {
    font-size: 1.5625em; }
  .callout_content {
    padding: 0em 2.1875em 0.625em; }
  .callout_text {
    font-size: 1em; }
  /* ------------------------------------------------------------------------ * * Divider
\* ------------------------------------------------------------------------ */
  .divider {
    font-size: 2em; }
  /* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 48em) and (max-width: 93.75rem) {
  .swiper-container {
    font-size: 1.066666666666667vw; } }

@media screen and (min-width: 48em) {
  .swiper-image {
    float: right;
    height: 41.25em;
    left: auto;
    transform: none; }
  .swiper-caption {
    border-width: 0.25rem;
    box-shadow: 0rem 0rem 0.25rem #000000;
    left: 0;
    margin: 0.125rem;
    padding: 2.375em 2.8125em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40%; }
  .swiper-title {
    font-size: 3.8125em; }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    position: absolute;
    padding-left: 2.8125rem;
    padding-right: 2.8125rem; }
  .swiper-pagination-bullet {
    border-width: 0.125rem;
    height: 0.9375rem;
    width: 0.9375rem; }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0.4375rem; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header-block.-fullbleed {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem; }
  .header_inner {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem; }
  .header_logo {
    margin: 0;
    max-width: 21.25rem; }
  /* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
  .hero_inner {
    padding: 0rem 1.25rem; }
  .hero_header {
    background: rgba(0, 0, 0, 0.8); }
  .hero_figure > .hero_header {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2; }
  /* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
  .content-block {
    padding: 2.5rem; }
  .content_inner {
    box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.2), inset 0rem 0rem 0rem 0.375rem #FFFFFF, inset 0rem 0rem 0rem 0.5rem #9AAAB7;
    padding: 3.125rem 3.75rem; }
  .content-block.-fullbleed > .content_inner {
    background: none;
    box-shadow: none;
    padding-bottom: 3.125rem;
    padding-top: 3.125rem; }
  .content-block.-fullbleed > .content_inner:last-child:not(:only-child):before {
    height: 6.25rem; }
  .content_figure {
    box-shadow: inset 0rem 0rem 0rem 0.125rem #9AAAB7;
    padding: 0.375rem; }
  .content_sidebar {
    width: 22.5em; } }

@media screen and (min-width: 48em) and (max-width: 80em) {
  .content_sidebar {
    font-size: 1.25vw; } }

@media screen and (min-width: 48em) {
  /* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
  .footer-block.-fullbleed {
    box-shadow: inset 0rem 0.375rem 0rem #000000, inset 0rem 0.5625rem 0rem #FFFFFF; }
  .footer_inner {
    box-shadow: inset 0rem 0.375rem 0rem #000000, inset 0rem 0.5625rem 0rem #FFFFFF; }
  .footer_title {
    font-size: 1.4375em;
    text-align: left; }
  .footer_text {
    font-size: 0.875em;
    text-align: left; }
  .footer_logo {
    margin-bottom: 0;
    width: 21.25em; } }

@media screen and (min-width: 53.3125em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 60em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 64em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 80em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }
