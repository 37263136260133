// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    &.-fullbleed {
        box-shadow: inset remify(0 6 0) $page_background, inset remify(0 9 0) $light;
    }
}

.footer_inner {
    & {
        box-shadow: inset remify(0 6 0) $page_background, inset remify(0 9 0) $light;
    }
}

.footer_title {
    & {
        font-size: remify(23, 16);
        text-align: left;
    }
}

.footer_text {
    & {
        font-size: remify(14, 16);
        text-align: left;
    }
}

.footer_logo {
    & {
        margin-bottom: 0;
        width: remify(340, 16);
    }
}
