// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */
.header-block {
    &.-fullbleed {
        padding-bottom: remify(20);
        padding-top: remify(20);
    }
}

.header_inner {
    & {
        padding-bottom: remify(20);
        padding-top: remify(20);
    }
}

.header_logo {
    & {
        margin: 0;
        max-width: remify(340);
    }
}
