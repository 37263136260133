// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: $primary;
        background-clip: padding-box;
        border: remify(3) solid transparent;
        outline: remify(1) solid $primary;
        color: $light;
        cursor: pointer;
        font-family: $heading-font;
        font-size: remify(20, 16);
        margin: 0;
        padding: remify(7 35 5 70, 20);
        position: relative;
        transition: background 0.15s, box-shadow 0.15s;
    }

    &:focus,
    &:hover {
        background-color: $primary_alt;
        box-shadow: remify(0 0 0 0.5) $primary_alt;
    }

    &:before,
    &:after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(2, 20);
        left: remify(35, 20);
        margin: auto;
        position: absolute;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(20, 20);
    }

    &.is-active:before,
    &.is-active:after {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }

    &:before {
        box-shadow: remify(0 6 0 0, 20) currentColor;
        top: remify(-12, 20);
    }

    &.is-active:before {
        box-shadow: 0 0 0 0 transparent;
        transform: rotate(45deg);
        top: 0;
    }

    &:after {
        top: remify(12, 20);
    }

    &.is-active:after {
        transform: rotate(-45deg);
        top: 0;
    }
}
