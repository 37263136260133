// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout {
    & {
        box-shadow: remify(0 0 6) transparentize($dark, 0.8), inset remify(0 0 0 6) $background, inset remify(0 0 0 8) $accent;
    }

    .row:last-of-type > .col > &:last-child {
        margin-bottom: 0;
    }
}

.callout_header {
    & {
        padding: remify(25 35 16);
    }
}

.callout_title {
    & {
        font-size: remify(25, 16);
    }
}

.callout_content {
    & {
        padding: remify(0 35 10, 16);
    }
}

.callout_text {
    & {
        font-size: remify(16, 16);
    }
}
