// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
        background: url("../media/background-fabric.jpg") center top no-repeat, linear-gradient(to bottom, $accent remify(0), $accent remify(320), transparentize($accent, 1) remify(320)), url("../media/background-wood.jpg") center top repeat-y;
        padding: remify(50 0);
    }

    &.-fullbleed {
        background: url("../media/background-wood.jpg") center top repeat-y;
        padding: remify(0 25);
    }

    .is-fluidboxactive & {
        position: static;
    }
}

.content_inner {
    & {
        background: $background;
        box-shadow: remify(0 0 6) transparentize($dark, 0.8), inset remify(0 0 0 6) $background, inset remify(0 0 0 8) $accent;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(40 25);
    }

    .content-block.-fullbleed > & {
        background: none;
        box-shadow: none;
        padding: remify(25 0);
        position: relative;
    }

    .content-block.-fullbleed > &:first-child:not(:only-child):before {
        background: $background;
        bottom: 0;
        content: "\0020";
        display: block;
        left: 50%;
        margin: 0 -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;
    }

    .content-block.-fullbleed > &:only-child:before {
        border-bottom: remify(8) solid $accent;
    }

    .content-block.-fullbleed > &:last-child:not(:only-child) {
        padding-top: 0;
    }

    .content-block.-fullbleed > &:last-child:not(:only-child):before {
        background: $background;
        border-bottom: remify(8) solid $accent;
        content: "\0020";
        display: block;
        height: remify(35);
        left: 50%;
        margin: 0 -50vw;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;
    }
}

.content_row {
    &.-padded {
        width: calc(100% + #{remify(60)});
    }

    &.-padded > .col {
        padding-right: remify(60);
    }
}

.content_figure {
    & {
        margin: remify(0 0 15);
    }
}

.content_image {
    & {
        display: block;
        width: 100%;
    }
}


.content_post {
    & {
        max-width: remify($site-width - 230);
        margin: 0 auto;
    }
}
