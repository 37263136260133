// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        font-size: remify(32, 16);
    }
}
