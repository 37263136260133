// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background:
            url("../media/background-divider_circle.svg") left center / remify(6 6, 16) no-repeat,                                    // left circle
            url("../media/background-divider_square.svg") remify(3, 16) center / calc(50% - #{remify(25.5, 16)}) remify(2, 16) no-repeat,       // left line
            url("../media/logo-icon.svg") center center / remify(35 35, 16) no-repeat,                                                  // icon
            url("../media/background-divider_square.svg") right remify(3, 16) center / calc(50% - #{remify(25.5, 16)}) remify(2, 16) no-repeat, // right line
            url("../media/background-divider_circle.svg") right center / remify(6 6, 16) no-repeat;                                   // right circle
        border: 0;
        clear: both;
        font-size: remify(16, 16);
        height: remify(35, 16);
        margin: remify(0 0 25, 16);
        width: 100%;
    }
}
