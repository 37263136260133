// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
        display: block;
    }

    &.-fullbleed {
        background: $page_background;
        box-shadow: inset remify(0 3 0) $page_background, inset remify(0 4.5 0) $light;
        padding: remify(30 25);
    }
}

.footer_inner {
    & {
        background: $page_background;
        box-shadow: inset remify(0 3 0) $page_background, inset remify(0 4.5 0) $light;
        margin: 0 auto;
        max-width: remify($site-width - 500);
        padding: remify(30 25);
    }

    .footer-block.-fullbleed > & {
        background: none;
        box-shadow: none;
        padding: 0;
    }
}

.footer_link {
    & {
        color: $accent;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}

.footer_title {
    & {
        color: $accent;
        font-size: remify(18.4, 16);
        text-align: center;
        margin-bottom: 0;
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(11, 16);
        text-align: center;
    }
}

.footer_logo {
    & {
        margin: remify(0 auto 25);
        width: remify(230, 16);
    }
}
