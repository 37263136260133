// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        padding: remify(40);
    }
}

.content_inner {
    & {
        box-shadow: remify(0 0 6) transparentize($dark, 0.8), inset remify(0 0 0 6) $background, inset remify(0 0 0 8) $accent;
        padding: remify(50 60);
    }

    .content-block.-fullbleed > & {
        background: none;
        box-shadow: none;
        padding-bottom: remify(50);
        padding-top: remify(50);
    }

    .content-block.-fullbleed > &:last-child:not(:only-child):before {
        height: remify(100);
    }
}

.content_figure {
    & {
        box-shadow: inset remify(0 0 0 2) $accent;
        padding: remify(6);
    }
}

.content_sidebar {
    & {
        width: remify(360, 16);
    }

    @media screen and (max-width: $xxl-break) {
        font-size: 1.25vw;
    }
}
