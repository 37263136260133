// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_inner {
    & {
        padding: remify(0 20);
    }
}

.hero_header {
    & {
        background: transparentize($dark, 0.2);
    }

    .hero_figure > & {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 2;
    }
}
