// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }

    &.-fullbleed {
        background: $page_background;
        padding: remify(20 25 40);
    }
}

.header_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(20 25 40);
    }

    .header-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.header_logo {
    & {
        margin: 0 auto;
        max-height: remify(170);
        max-width: remify(270);
    }
}

.header_menu-toggle {
    & {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%) translateY(-50%);
    }
}
