// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

// excerpt variant

.article.-excerpt {
    &:not(:last-of-type){
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}

// testimonial variant

.article.-testimonial {
    .article_text {
        color: $primary;
        font-size: remify(14.4, 16);
    }

    .article_user-content p,
    .article_user-content ol,
    .article_user-content ul,
    .article_user-content table {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(20, 16);
        line-height: remify(26, 20);
        margin-bottom: remify(16, 20);
        text-align: center;
    }
}
