// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
    }
}

.swiper-slide {
    & {
        margin: 0;
        overflow: hidden;
    }
}

.swiper-picture {
    & {
        flex: none;
        position: relative;
        z-index: 1;
    }
}

.swiper-image {
    & {
        display: block;
        height: remify(330, 16);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

.swiper-caption {
    & {
        background: $dark;
        background-clip: padding-box;
        border: remify(3) solid transparent;
        flex: 1 1 auto;
        margin: remify(-38 12 12);
        outline: remify(2) solid $dark;
        padding: remify(20 22.5);
        position: relative;
        z-index: 2;
    }
}

.swiper-link {
    & {
        color: $accent;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}

.swiper-title {
    & {
        color: $light;
        font-size: remify(30.5, 16);
        line-height: remify(37.5, 30.5);
        margin-bottom: remify(8, 30.5);
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        transition: opacity 0.15s;
        top: 0;
        width: remify(55, 16);
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    &:focus {
        opacity: 1;
    }

    .fa {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    & {
        background: none;
        left: 0;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    & {
        background: none;
        right: 0;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        padding: remify(0 36 20);
        position: static;
        text-align: left;
    }
}

.swiper-pagination-bullet {
    & {
        background: $dark;
        border: remify(1) solid $dark;
        height: remify(10);
        opacity: 1;
        transition: background 0.15s, border 0.15s;
        width: remify(10);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(4);
    }
}

.swiper-pagination-bullet-active {
    & {
        background-color: $light;
        border-color: $primary;
    }
}
