// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Login
\* ------------------------------------------------------------------------ */

.login-block {
    & {
        @include layer("login");
    }

    &.-fullbleed {
        background: $background;
    }

    &.-overlay {
        display: block;
        z-index: 999;
    }
}

.login_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .login-block.-fullbleed > & {
        background: none;
    }

    .login-block.-overlay > & {
        background: $background;
        border: remify(1) solid darken($background, 20);
        left: -999em;
        max-height: 100vw;
        max-width: 100vw;
        opacity: 0;
        position: fixed;
        transform: translateX(-50%) translateY(-50%);
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        top: -999em;
        width: remify($site-width / 2);
        visibility: hidden;
    }

    .login-block.-overlay.is-active > & {
        left: 50%;
        opacity: 1;
        top: 50%;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

.login_login-form {
    & {
        padding: remify(30 40 0);
    }

    &.-register {
        background: darken($background, 10);
        border-top: remify(1) solid darken($background_alt, 10);
    }

    .login-form_button {
        margin-bottom: remify(-10, 14);
    }
}

.login_button.-close {
    & {
        background: none;
        color: darken($background_alt, 10);
        font-size: remify(16, 16);
        height: remify(32, 16);
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: color 0.15s;
        width: remify(32, 16);
    }

    &:focus,
    &:hover {
        color: $accent;
    }

    &:before,
    &:after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(1);
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: remify(12, 16);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}
