// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }

    &.-fullbleed {
        /* bgImage: ignore */
        background-color: $accent;
        background-image: url("../media/background-fabric.jpg");
        background-position: center top;
        background-repeat: repeat-y;
    }
}

.hero_inner {
    & {
        /* bgImage: ignore */
        background-color: $accent;
        background-image: url("../media/background-fabric.jpg");
        background-position: center top;
        background-repeat: repeat-y;
        margin: 0 auto;
        max-width: remify($site-width + 40);
    }

    .hero-block.-fullbleed & {
        background: none;
    }
}

.hero_figure {
    & {
        margin: 0;
        position: relative;
    }
}

.hero_image {
    & {
        display: block;
        width: 100%;
    }
}

.hero_header {
    & {
        background: $dark;
    }
}

.hero_title {
    & {
        color: $light;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(15 25, 24);
    }

    .hero_container.-fullbleed & {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.hero_user-content {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
        overflow: hidden;
        padding: remify(0 25, 16);
    }
}
