// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    & {
        display: block;
        text-decoration: none;
        transition: opacity 0.15s;
    }

    &:not(.-nohover):hover {
        opacity: 0.9;
    }
}

.logo_image {
    & {
        display: block;
        width: 100%;
    }
}

// 'cause apparently you can't add a class to the_custom_logo() -_-

.custom-logo {
    & {
        @extend .logo_image;
        height: auto !important;
    }
}
