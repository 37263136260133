// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-container {
    @media screen and (max-width: remify($site-width)) {
        font-size: 1.066666666666667vw;
    }
}

.swiper-image {
    & {
        float: right;
        height: remify(660, 16);
        left: auto;
        transform: none;
    }
}

.swiper-caption {
    & {
        border-width: remify(4);
        box-shadow: remify(0 0 4) $dark;
        left: 0;
        margin: remify(2);
        padding: remify(38 45, 16);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
    }
}

.swiper-title {
    & {
        font-size: remify(61, 16);
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        position: absolute;
        padding-left: remify(45);
        padding-right: remify(45);
    }
}

.swiper-pagination-bullet {
    & {
        border-width: remify(2);
        height: remify(15);
        width: remify(15);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(7);
    }
}
